<template>
  <div class="image-container">
    <div class="container">
      <div class="item" v-for="(item, index) in images">
        <img :src="item.webp"/>
      </div>
    </div>
  </div>
</template>
<script>
export default ({
  props: {
    images: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {}
  }
})

</script>

<style scoped lang="scss">
.image-container {
  .container {
    column-count: 6;
    column-gap: 15px;

  }

  .item {
    //display: inline-block;
    width: 100%;
    margin-bottom: 15px;
  }

  img {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }

  img:hover {
    transform: scale(1.03);
    cursor: pointer;

  }

}
</style>